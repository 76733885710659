import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Slider from './slider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  line-height: 1.4;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:hover {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  }
  &.itemActive {
    outline: 1px solid var(--color-primary);
  }
`;

const SliderWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Footer = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 75px;
  justify-content: center;
  padding: 15px 10px;
  flex-shrink: 0;
  .title {
    font-weight: bold;
  }
  .amenities {
    font-size: 14px;
  }
  .address {
    color: #777;
    font-size: 14px;
  }
`;

const Apply = styled(Button)`
  position: absolute;
  top: 15px;
  left: 10px;
  padding: 7px 12px;
  font-weight: bold;
  color: #444;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  border-radius: 25px;
  font-size: 14px;
`;

export default ({ location = {}, onClick, isActive }) => {
  const { displayInfo = {} } = location;

  const handleApply = (event) => {
    // This goes to app create, it will bounce and redirect as needed
    event.preventDefault();
    event.stopPropagation();
    window.open(
      `/${location.companyInfo.id}/applicant/app/applications/form/create/?cId=${location._id}`,
      '_blank'
    );
  };

  const detailItems = React.useMemo(() => {
    const firstRowItems = [];
    if (displayInfo.bedCount) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.bedCount}</strong> beds
        </>
      );
    }
    if (displayInfo.bathCount) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.bathCount}</strong> baths
        </>
      );
    }
    if (displayInfo.squareFeet) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.squareFeet}</strong> sf
        </>
      );
    }

    return firstRowItems;
  }, [location]);

  return (
    <>
      <Wrapper
        onClick={() => onClick(location)}
        className={`${isActive ? 'itemActive' : ''} propertyItem`}
        id={location._id}>
        <SliderWrapper>
          <Slider photos={displayInfo.photos} />
        </SliderWrapper>
        <Footer>
          {!!displayInfo.formattedPrice && (
            <div className="title">{displayInfo.formattedPrice}/mo</div>
          )}
          {detailItems.length ? (
            <div className="amenities">
              {detailItems.map((detailItem, ix) => (
                <span key={ix} className="amenities-item">
                  {detailItem}
                  {ix === detailItems.length - 1 || (ix + 1) % 3 === 0 ? '' : ' | '}
                </span>
              ))}
            </div>
          ) : (
            <></>
          )}
          <div className="address">{displayInfo.title}</div>
          {!!location?.distanceFromCenter && (
            <div className="address">
              {location?.distanceFromCenter?.toLocaleString('fullwide', {
                maximumFractionDigits: 2
              })}{' '}
              miles away
            </div>
          )}
        </Footer>
        {!!location.aptlyScreening?.active && <Apply onClick={handleApply}>Apply Now</Apply>}
      </Wrapper>
    </>
  );
};
