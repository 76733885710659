import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  input {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
    font-size: ${({ fontSize = 14 }) => fontSize}px;
    background-color: #fff175;
  }
  &.mode-view {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

export default ({ block, mode, updateBlock }) => {
  return (
    <Wrapper className={`mode-${mode}`} fontSize={block.fontSize}>
      {mode === 'edit' && (
        <input
          className="textControl"
          value={block.value || ''}
          onChange={(e) => {
            if (mode !== 'edit') return;
            updateBlock({ uuid: block.uuid, value: e.target.value });
          }}
          type="text"
        />
      )}
      {mode === 'view' && <div className="">{block.value}</div>}
    </Wrapper>
  );
};
